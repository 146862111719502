import React, {useContext, useEffect, useState} from "react"
import "./index.scss"
import CartContext from "../../context/cartContext";
import {getPowerOptions} from "../ContactsDetailsMobile";
import {selectDefaultValue} from "../../services/utils";
import {colorTags} from "../../services/data";
import scrollTo from "gatsby-plugin-smoothscroll";
import {EnterRXWrapper} from "../RXComponent";
import NewCustomSelect from "../NewCustomSelect";
import NewButton from "../NewButton";
import {useWindowWidth} from "../../services/hooks";

const ContactsDetailsNew = ({
  item,
  onClick,
  supplyDescription,
  subscriptionDescription,
  additionalDescription,
}) => {
  const { cartItems, currentItem } = useContext(CartContext)
  const { title, offPrice, price, percentOff } = item

  const hubble_lenses = cartItems.filter(obj => {
    return obj.type === "contacts"
  })
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth <= 992)
  }, [windowWidth])

  const { order, setOrder } = useContext(CartContext)
  const [prescription, setPrescription] = useState({
    leftEye: {
      power: order?.prescription?.leftEye?.power,
      baseCurve: item.baseCurve,
      diameter: item.diameter,
    },
    rightEye: {
      power: order?.prescription?.rightEye?.power,
      baseCurve: item.baseCurve,
      diameter: item.diameter,
    },
  })
  const [rightPowerOptions] = useState(getPowerOptions('right', false, true))
  const [leftPowerOptions] = useState(getPowerOptions('left', false, true))
  const [rightPowerValue, setRightPowerValue] = useState(selectDefaultValue(rightPowerOptions, prescription?.rightEye?.power))
  const [leftPowerValue, setLeftPowerValue] = useState(selectDefaultValue(leftPowerOptions, prescription?.leftEye?.power))
  const [isEmptyValues, setIsEmptyValues] = useState(false)

  useEffect(() => {
    setPrescription({
      leftEye: {
        power: order?.prescription?.leftEye?.power,
        baseCurve: item.baseCurve,
        diameter: item.diameter,
      },
      rightEye: {
        power: order?.prescription?.rightEye?.power,
        baseCurve: item.baseCurve,
        diameter: item.diameter,
      },
    })

    if (!order?.prescription?.rightEye?.power) setRightPowerValue(null)
    if (!order?.prescription?.leftEye?.power) setLeftPowerValue(null)

    if (
      order?.prescription?.rightEye?.power === "oneEye" ||
      order?.prescription?.leftEye?.power === "oneEye"
    ) {
      setOrder({ ...order, isOneEye: true })
    } else if (
      !["leftEye", "rightEye"].includes(order?.isOneEye)
    ) {
      setOrder({...order, isOneEye: false})
    }
  }, [currentItem])

  const handlePowerChange = (eye, value) => {
    if (eye === "rightEye") setRightPowerValue(selectDefaultValue(rightPowerOptions, value))
    if (eye === "leftEye") setLeftPowerValue(selectDefaultValue(leftPowerOptions, value))
    const isOneEye = value === 'oneEye'
    const newEye = { ...prescription[eye], power: value }
    const newPrescription = { ...prescription, [eye]: newEye }
    const newOrder = { ...order, prescription: newPrescription, isOneEye }
    setOrder(newOrder)
    setPrescription(newPrescription)
  }

  const handleBuyBtn = () => {
    if (!(leftPowerValue && rightPowerValue) || (prescription?.rightEye?.power === 'oneEye' && prescription?.leftEye?.power === 'oneEye')) {
      setIsEmptyValues(true)
      isMobile && (scrollTo(".price-container") || scrollTo(".pack-options"))
      return
    }

    setIsEmptyValues(false)
    onClick()
  }

  return (
    <div className="contacts-details-container">
      <div className="product-details">
        {!isMobile && <h1 className="text h3 heading" dangerouslySetInnerHTML={{__html: title}}></h1>}

        <div className="price-container">
          <h4 className="lenses-subheader">{supplyDescription}</h4>

          <div className="price-wrapper">
            {offPrice ?
              <span className="text h7 price">
                <s>${price.toFixed(2)}/eye</s> <b>${offPrice.toFixed(2)}/eye {isMobile && <br/>}({percentOff}% off)</b> for your first order
              </span>
              :
              <span className="text h7 price">${price.toFixed(2)}</span>
            }
          </div>
        </div>

        <div className="divider"></div>

        {subscriptionDescription && <div className="price-description">{subscriptionDescription}</div>}

        {additionalDescription && <div className="additional-description">{additionalDescription}</div>}

        <div className="rx-wrapper">
          <EnterRXWrapper title="Enter Your Prescription" color="purple">
            <div className="power-row">
              <div className="select-container">
                <p className="title text h8 bulky ocean-blue">Right Eye (OD)</p>
                <NewCustomSelect
                  value={rightPowerValue}
                  name='power'
                  onChange={({ value }) => handlePowerChange("rightEye", value)}
                  options={rightPowerOptions}
                  placeholder={"Select"}
                  error={!prescription.rightEye.power && prescription.rightEye.power !== "oneEye" && isEmptyValues}
                />
              </div>
              <div className="select-container">
                <p className="title text h8 bulky ocean-blue">Left Eye (OS)</p>
                <NewCustomSelect
                  value={leftPowerValue}
                  name='power'
                  onChange={({ value }) => handlePowerChange("leftEye", value)}
                  options={leftPowerOptions}
                  placeholder={"Select"}
                  error={!prescription.leftEye.power && prescription.leftEye.power !== "oneEye" && isEmptyValues}
                />
              </div>
            </div>
          </EnterRXWrapper>
        </div>

        <NewButton
          label="Continue to Checkout"
          color={colorTags.contacts}
          onClick={handleBuyBtn}
          className="submit-button"
          disabled={hubble_lenses?.length !== 0}
          stickyScroll={isMobile}
        />

        {isMobile &&
          <ContactsProperties
            manufacturer={item.manufacturer}
            baseCurve={item.baseCurve}
            diameter={item.diameter}
            material={item.material}
            description={item.subheader}
          />
        }
      </div>
    </div>
  )
}

const ContactsProperties = ({ baseCurve, diameter, material, manufacturer, description }) => {
  return (
    <div className="properties-container">
      <div className="properties-list">
        <PropertyItem title="Base Curve (BC)" type="base-curve">
          <span>{baseCurve.toFixed(1)}</span>
        </PropertyItem>
        <PropertyItem title="Diameter (DIA)" type="diameter">
          <span>{diameter.toFixed(1)}</span>
        </PropertyItem>
        <PropertyItem title="Manufacturer" type="manufacturer">
          <span>{manufacturer}</span>
        </PropertyItem>
        <PropertyItem title="Material" type="material">
          <span>{material}</span>
        </PropertyItem>
      </div>

      {description &&
        <span className="description">{description}</span>
      }
    </div>
  )
}

const PropertyItem = ({ title, children, type }) => (
  <div className="property-item" data-type={type}>
    <span className="property-head">{title}</span>
    <div className="property-body">{children}</div>
  </div>
)

export default ContactsDetailsNew
