import ProductSlider from "../ProductSlider";
import ContactsDetailsNew from "../ContactsDetailsNew";
import React, {useEffect, useState} from "react";
import {useWindowWidth} from "../../services/hooks";
import "./index.scss"


const ContactsTopSection = ({
  item,
  slides,
  handleGaClick,
  tagText,
  tagColor,
  additionalDescription,
  subscriptionDescription,
  supplyDescription,
}) => {
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth < 992)
  }, [windowWidth])

  return (
    <div className="contacts-top-section">
      {isMobile && <>
        <h1 className="heading" dangerouslySetInnerHTML={{__html: item.title}}></h1>
      </>}
      <ProductSlider
        slides={slides}
        type="contacts"
        product={item}
        containImage
        centerImage
        tagText={tagText}
        tagColor={tagColor}
        showDetails
        slidesFit="contain"
      />
      <ContactsDetailsNew
        item={item}
        type="contacts"
        onClick={() => handleGaClick()}
        supplyDescription={supplyDescription}
        subscriptionDescription={subscriptionDescription}
        additionalDescription={additionalDescription}
      />
    </div>
  )
}

export default ContactsTopSection
