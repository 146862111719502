import React, { useContext, useEffect, useState } from "react"
import { navigate } from "gatsby"
import "slick-carousel/slick/slick.css"
import "../../styles/pages/contact-lenses.scss"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CartContext from "../../context/cartContext"
import VideoModule from "../../components/VideoModule"
import ProductSectionAdvantages from "../../components/ProductSectionAdvantages"
import HowHubbleWorksCarousel from "../../components/HowHubbleWorksCarousel"
import AboutProductSection from "../../components/AboutProductSection"
import Breadcrumbs from "../../components/Breadcrumbs"
import ProductValueProps from "../../components/ProductValueProps"
import {
  colorTags,
  skyhyFaq,
  hubbleValueProps,
  hubbleDifference,
  contactsRelatedProducts,
  getHubblePricing, skyhyNewAdvantages
} from "../../services/data"
import ProductWithHubble from "../../components/ProductWithHubble"
import FAQBlock from "../../components/FAQBlock"
import RelatedFrames from "../../components/RelatedFrames"
import { StaticImage } from "gatsby-plugin-image"
import NewRxFlow from "../../components/NewRxFlow"
import avgProducts from "../../services/avg-products.json";
import {hubbleList} from "../../components/ContactsPrescription/mockedData";
import {productInfo} from "../../components/Intake/Prescription/mockedData";
import Banner from "../../components/Banner";
import ContactsTopSection from "../../components/ContactsTopSection";
import {useWindowWidth} from "../../services/hooks";

const SkyhyMonthlyLenses = () => {
  const [slides] = useState([
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-Monthly-PDP-01.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-Monthly-PDP-02.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-Monthly-PDP-03.png",
    "Pages/ContactsPDP/Product-Images/SkyHy-by-Hubble-Monthly-PDP-04.png",
  ])
  const bulletPoints = [
    "Doctors’ top choice: 7 out of 10 new prescriptions are for silicone hydrogel lenses",
    "Monthly silicone hydrogel lenses designed for maximum breathability",
    "Single vision soft contact lenses made from Olifilcon A (a silicone hydrogel material)",
    "Special surface technology keeps moisture locked in for more comfortable, hydrated, all-day wear",
    "Flexible construction molds to the curve of your eye for a snug fit",
    "A smart multi-curvature design to prevent unwanted movement—even when you blink",
    "FDA Approved"
  ]
  const hubblePricing = getHubblePricing()
  const [show, setShow] = useState(false)
  const { cartItems, createOrder, setHeaderColor, currentItem } = useContext(
    CartContext
  )
  const [isMobile, setIsMobile] = useState(false)
  const windowWidth = useWindowWidth()

  useEffect(() => {
    setIsMobile(windowWidth <= 992)
  }, [windowWidth])

  const item = {
    title: "SkyHy by Hubble<sup>®</sup> Monthly Contact Lenses",
    subheader: "All the comfort and breathability of SkyHy is now available as a monthly lens to fit the wear schedule that’s right for you.",
    price: Number(hubblePricing.skyhyMonthly.subPrice),
    offPrice: Number(hubblePricing.skyhyMonthly.firstPrice),
    pricePerMonth: Number(hubblePricing.skyhyMonthly.subPricePerMonth),
    offPricePerMonth: Number(hubblePricing.skyhyMonthly.firstPricePerMonth),
    percentOff: Number(hubblePricing.skyhyMonthly.percentOff),
    baseCurve: 8.7,
    diameter: 14.0,
    material: "Olifilcon A\n(a silicone hydrogel material)",
    manufacturer: "Visco",
    id: 2,
    subscriptionSubtitle: "66% OFF Your First Order",
    tag: "New!",
    value: "skyhy-monthly",
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      sessionStorage.removeItem("previousPage")
      const urlParams = new URLSearchParams(window?.location.search)
      const rxflow = urlParams.get('rxflow')
      const newRxFlow = urlParams.get('newRxFlow')
      if (rxflow === "true" || newRxFlow === "true") {
        setShow(true)
      }


      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "view_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'SkyHy by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Olifilcon B',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42853286281416,
              price: 17.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      })
    }
  }, [])

  useEffect(() => {
    const newItem = {
      id: '',
      type: "contacts"
    }

    const newOrder = {
      email: "",
      state: "",
      doctor: "",
      prescription: {
        rightEye: {
          power: "",
          baseCurve: "8.7",
          diameter: "14.1",
        },
        leftEye: {
          power: "",
          baseCurve: "8.7",
          diameter: "14.1",
        },
      },
      isOneEye: false,
    }

    setHeaderColor("purple")
    if (!currentItem) createOrder(newItem, newOrder)
  }, [cartItems])

  useEffect(() => {
    return () => {
      setHeaderColor("black")
    }
  }, [])

  const schemaMarkup = [
    {
      "@context" : "https://schema.org/",
      "@type" : "Product",
      "name" : "SkyHy by Hubble Contact Lenses",
      "image" : "https://www.hubblecontacts.com/static/c63b8c7fedc861d5a54eef96097e3022/a18a4/SkyHy-by-Hubble-PDP-02.webp",
      "description" : "Our most breathable all-day wear daily contact lenses made with silicone hydrogel. Healthy eyes love oxygen. Oxygen makes contact lenses more comfortable. SkyHy by Hubble lenses have increased oxygen permeability for enhanced comfort and more breathability. New customers get 40% off!",
      "Brand":
      {
      "@type" : "Thing",
      "name" : "Hubble"
      },
      "Logo" : "https://cdn.shopify.com/s/files/1/1516/5228/t/3/assets/full_logo_red.svg?v=14192704095517354305",
      "Offers":
      [
      {
      "@type" : "Offer",
      "url " :"https://www.hubblecontacts.com/contact-lenses/skyhy-by-hubble-lenses/",
      "availability" : "https://schema.org/InStock",
      "Price":"$17.99/eye",
      "priceCurrency":"USD",
      "priceValidUntil":"2024-12-31"
      }
      ]
    },
    {
      "@context": "https://schema.org",
      "@type": "VideoObject",
      "name": "Hubble Contacts How We Make Ordering Breathable Contact Lenses Online Easy",
      "description": "At Hubble, we believe ordering our breathable silicone hydrogel contact lenses online should be easy. That’s why we’ve made Hubble’s personalized contact lens subscription service the easiest prescription daily contacts experience in the world. You can easily order breathable contacts in your contact lens prescription online. Order SkyHy by Hubble contacts and start your subscription of our daily silicone hydrogel prescription contact lenses.",
      "thumbnailUrl": "https://i.ytimg.com/vi/w4YCLxsUxyI/hqdefault.jpg",
      "uploadDate": "2023-03-20",
      "duration": "PT1M15S",
      "contentUrl": "https://www.hubblecontacts.com",
      "embedUrl": "https://www.youtube.com/embed/w4YCLxsUxyI"
    },
    {
      "@context": "https://schema.org",
      "@type": "FAQPage",
      "mainEntity": [
        {
          "@type": "Question",
          "name": "What are the differences between SkyHy by Hubble, Hydro by Hubble, and Hubble Classic Contact Lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Great question! Let’s start with the similarities: All of Hubble’s contact lenses are FDA-approved, offered at low prices, and ship for free directly to your door, as part of our customizable subscription service. You can compare our contacts to find the right option for you. If you’re looking for a little more detail, our Hydro by Hubble contact lenses are made from Hioxifilcon A and feature a higher water content. The material is designed to mimic and bind with natural tears, lock in moisture and quickly rehydrate your eyes. Plus, our hygienic touch technology packaging ensures minimal contact with your lenses and is the world’s slimmest. SkyHy by Hubble is the newest member of the Hubble family. Our most breathable contact lenses yet are made with silicone hydrogel, and feature increased oxygen permeability for enhanced comfort and special flexible material that keeps moisture locked and your eyes naturally hydrated throughout the day. SkyHy by Hubble Daily lenses and SkyHy by Hubble Monthly lenses both offer all the benefits of silicone hydrogel at affordable prices, for different wear schedules. SkyHy by Hubble Monthly brings you the latest in lens technology with our most cost efficient subscription."
          }
        },
        {
          "@type": "Question",
          "name": "Are Hubble prescription daily contact lenses FDA-approved?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, our Hubble prescription contact lenses have been FDA approved for sale in the US for over a decade."
          }
        },
        {
          "@type": "Question",
          "name": "Can teenagers wear Hubble’s breathable daily contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Yes, all patients ages 13+, who have a valid contact lens prescription, can wear Hubble daily breathable silicone hydrogel contact lenses. Please note: patients under the age of eighteen must receive parental permission before placing an order for our prescription contact lenses online with Hubble."
          }
        },
        {
          "@type": "Question",
          "name": "Does Hubble sell bi-weekly or monthly prescription contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "Hubble only sells daily contact lenses because wearing new prescription contact lenses every day is the safest and most comfortable way to wear contacts."
          }
        },
        {
          "@type": "Question",
          "name": "Can I use water or contact lens solution to clean or store my SkyHy by Hubble silicone hydrogel contact lenses?",
          "acceptedAnswer": {
            "@type": "Answer",
            "text": "No! SkyHy by Hubble lenses are daily contact lenses, designed for individual use. Please do not attempt to wear our prescription contact lenses for more than one day."
          }
        }
      ]
    },

    {
      "@context" : "https://schema.org",
      "@url" : "https://www.hubblecontacts.com/contact-lenses/skyhy-by-hubble-lenses/",
      "@type" : "webpage",
      "name" : "Hubble Contacts SkyHy Lenses: Most breathable all-day wear daily contact lenses",
      "description" : "SkyHy by Hubble lenses are breathable all-day wear daily contact lenses made with silicone hydrogel. 40% off for new customers.",
      "publisher" :
      {
      "@type" : "Organization",
      "name" : "Hubble"
      }
      }

  ]

  const handleGaClick = () => {
    navigate(`/contact-lenses/skyhy-monthly-by-hubble-lenses/?newRxFlow=true`)

    setShow(true)
    if (typeof window !== "undefined") {
      window.dataLayer.push({ ecommerce: null });
      window.dataLayer.push({
        event: "select_item",
        ecommerce: {
          items: [
            {
              item_id: 7546170802376,
              item_name: 'SkyHy by Hubble Contact Lenses',
              currency: "USD",
              discount: 0,
              item_brand: "Hubble",
              item_category: "contacts",
              item_category2: 'spherical',
              item_category3: 'N/A',
              item_category4: 'Olifilcon B (a silicone hydrogel material)',
              item_category5: 'N/A',
              item_list_id: `Contacts`,
              item_variant: 42853286281416,
              price: 17.99,
              quantity: 1,
              index: 0
            }
          ]
        }
      });
    }
  }

  const validateBrandPrescription = (item, prescription) => {
    const hubbleBrand =
      item.title.toLowerCase().includes("hubble classic") && "default" ||
      item.title.toLowerCase().includes("skyhy by") && "skyhy" ||
      item.title.toLowerCase().includes("hydro by") && "hydro"
    const prescriptionSettings = {...productInfo[hubbleBrand || item.cc_product_id]};
    prescriptionSettings.eye_powers = [
      { value: 'oneEye', label: `` },
      ...prescriptionSettings.eye_powers
    ]
    const eyes = [prescription.leftEye, prescription.rightEye]

    if (eyes[0].power === "oneEye" && eyes[1].power === "oneEye") return false;

    return eyes.every(eye => {
      if (eye.power === "oneEye") return true;

      return Object.entries(eye).every(([key, value]) => {
        let customKeys = {
          "power": "eye_powers",
          "addPower": "add_power",
          "baseCurve": "base_curve_values"
        }

        let _key = customKeys[key] || key;

        if (key === "diameter" || key === "baseCurve" && (
          !Array.isArray(prescriptionSettings[_key]) ||
          Array.isArray(prescriptionSettings[_key]) && prescriptionSettings[_key].length < 2)
        )
          return true

        if (typeof prescriptionSettings[_key] === "object")
          if (typeof prescriptionSettings[_key][0] === "object") {
            return prescriptionSettings[_key].some(({value: settingsValue}) => settingsValue == value)
          } else {
            return prescriptionSettings[_key].some((settingsValue) => settingsValue == value)
          }
        else
          return prescriptionSettings[_key] == value
      }) && Object.keys(prescriptionSettings).every(key => {
        let customKeys = {
          "eye_powers": "power",
          "add_power": "addPower",
          "base_curve_values": "baseCurve"
        }

        let _key = customKeys[key] || key;

        return !!eye[_key]
      })
    })
  }

  return (
    <Layout className="hubble-lenses">
      <SEO
        title="Hubble Contacts SkyHy Lenses: Breathable Silicone Hydrogel Contact Lenses"
        description="SkyHy by Hubble lenses are breathable daily contacts made with silicone hydrogel. Start your automatic subscription and never run out of prescription contacts. Exclusive discount for new customers."
        schemaMarkup={schemaMarkup} />
      <Banner
        bannerClass="purple discount-banner promo-banner pcp extra-padding"
        bannerText="Get our NEW SkyHy by Hubble Monthly for <b>40% OFF</b> of your first order."
      />
      <ContactsTopSection
        slides={slides}
        item={item}
        tagText="New!"
        tagColor="charged-blue"
        handleGaClick={handleGaClick}
        supplyDescription={<><b>6 Monthly Disposable Lenses</b> {isMobile && <br/>}(6-Month Supply)</>}
        subscriptionDescription={<>
          <span><b>${item.offPricePerMonth.toFixed(2)}/eye</b> {isMobile && <br/>}per month for first 6 months</span>
          <span><b>${item.pricePerMonth.toFixed(2)}/eye</b> {isMobile && <br/>}per month for recurring 6 months</span>
        </>}
        additionalDescription={<>Our <b>most affordable</b> monthly subscription cost compared to other Hubble contact lenses, with all the benefits of premium silicone hydrogel technology.</>}
      />
      <ProductValueProps props={hubbleValueProps(true)} type="contacts" />
      <div className="product-info">
        <div className="grid-section">
          <Breadcrumbs
            links={[
              { to: "/", label: "Homepage" },
              { to: "/contact-lenses/hubble", label: "Hubble Contact Lenses" },
              { label: "SkyHy by Hubble Monthly Contact Lenses" },
            ]}
          />
        </div>
        <AboutProductSection
          title="Hubble Lenses"
          subtitle="BREATHABLE SILICONE HYDROGEL MONTHLY CONTACT LENSES"
          type="contacts"
          description={<>SkyHy by Hubble<sup>®</sup> Monthly contact lenses boast the latest in contact lens technology with their premium silicone hydrogel material, at an affordable price for 6 months of daily wear.</>}
          bullet_points={bulletPoints}
        />
        <ProductSectionAdvantages list={skyhyNewAdvantages} />

        <HowHubbleWorksCarousel color={colorTags.contacts} />
        <div className="grid-section lifestyle-shot">
          <div className="desktop-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image.jpg"
              alt="Cheerful woman putting in her SkyHy by Hubble breathable contact lens"
            />
          </div>
          <div className="mobile-lifestyle">
            <StaticImage
              src="../../images/Pages/ContactsPDP/Hubble-Contacts-PDP-Inset-Image-Mobile.jpg"
              alt="Cheerful woman putting in her SkyHy by Hubble breathable contact lens"
            />
          </div>
        </div>
        <ProductWithHubble type="contacts" items={hubbleDifference} />
        <VideoModule
          type="contacts"
          video={"https://www.youtube.com/embed/quoNFn7dxTw"}
          image="Pages/ContactsPDP/Hubble-Contacts-PDP-Video-Still.jpg"
          alt_text="Woman unboxing a new delivery of Hubble Contacts"
        />
        <FAQBlock faqList={skyhyFaq} color={colorTags.contacts} />
      </div>
      <NewRxFlow type="contacts" show={show} setShow={setShow} brand={item.value} onHide={() => setShow(false)}
                 validateBrandPrescription={validateBrandPrescription}
                 optionsList={[{options: [...avgProducts.flatMap(e => e.options), ...hubbleList(hubblePricing)]}]}/>
      <RelatedFrames
        relatedFrames={contactsRelatedProducts}
        type="contacts"
        linkTo="/contact-lenses"
      />
    </Layout>
  )
}

export default SkyhyMonthlyLenses
